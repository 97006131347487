<template>
    <div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">

            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoading="isLoading"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoading || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>

                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('clear_all') : getCommonText('clear')}`)"/>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-if="!data.item.isHasChild"
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(scholar_list)="data">
                    <div :class="{'child-item': data.item.parent_code}">{{ data.item.name_local }}</div>
                </template>
                <template #cell(avg_annual)="data">
                    <template v-if="variantAttribute">
                        <b-form-input
                                    v-if="!data.item.isHasChild"
                                    class="text-right"
                                    :disabled="isLoading"
                                    :value="data.item.avg_annual"
                                    @change="v => data.item.avg_annual = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'avg_annual', data.item.avg_annual, 1)">
                        </b-form-input>
                        <div v-else class="text-right">x</div>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('avg_annual')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(salary)="data">
                    <template v-if="variantAttribute">
                        <b-form-input
                                    v-if="!data.item.isHasChild"
                                    class="text-right"
                                    :disabled="isLoading"
                                    :value="data.item.salary"
                                    @change="v => data.item.salary = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'salary', data.item.salary, 2)">
                        </b-form-input>
                        <div v-else class="text-right">x</div>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('salary')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(surcharge)="data">
                    <template v-if="variantAttribute">
                        <b-form-input
                                    v-if="!data.item.isHasChild"
                                    class="text-right"
                                    :disabled="isLoading"
                                    :value="data.item.surcharge"
                                    @change="v => data.item.surcharge = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'surcharge', data.item.surcharge, 2)">
                        </b-form-input>
                        <div v-else class="text-right">x</div>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(amount_months)="data">
                    <template v-if="variantAttribute">
                        <b-form-input
                                    v-if="!data.item.isHasChild"
                                    class="text-right"
                                    maxlength = "4"
                                    :disabled="isLoading"
                                    :value="data.item.amount_months"
                                    @change="v => data.item.amount_months = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'amount_months', data.item.amount_months, 1)">
                        </b-form-input>
                        <div v-else class="text-right">x</div>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('amount_months')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div v-else>{{ data.value }}</div>
                </template>

                <template #cell()="data">
                    <div class="text-right">{{ isNaN(data.value) ? 0 : $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        v-if="!data.item.isHasChild"
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center" v-if="!data.item.isHasChild">
                        <i 
                            :title="getCommonText('clear_rec')"
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItem(`${getCommonText('clear_rec')}`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-left" colspan="6">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="isLoading"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_324',
    components: { FilesUpdown, BudgetFormsList, FormsDownloadReprt, ModalFilesManagementNodecode, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-324',
                name_ru: 'Расчет расходов на выплату стипендии студентам, интернам, магистрантам, докторантам, слушателям, курсантам военно-учебных специальных учебных заведений и кадетов',
                name_kk: 'Әскери-оқу арнайы оқу орындары мен кадеттерiнiң студенттеріне, интерндеріне, магистранттарына, докторанттарына, тыңдаушыларына курсанттарына стипендиялар төлеуге арналған шығыстарды есептеу'
            },
            variantAttribute: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            isLoading: true,
            openDisabled: false,
            row_files: [],
            newRowStartId: -1,
            baseRowFiles: [],
            budgetData: [],
            validationFields: ['avg_annual', 'salary', 'surcharge', 'amount_months']
        };
    },
    created() {
        this.$emit('setForm', this.form);
    },

    watch: {
        openDisabled() {
            this.$emit('setOpenDisabled', this.openDisabled);
        },
        categoryMode() {
            this.$emit('setCategoryMode', this.categoryMode);
        },
        progress() {
            this.$emit('setProgress', this.progress);
        }
    },

    methods: {
        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoading 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            
            const resetRow = (row) => {
                this.$set(row, 'avg_annual', 0);
                this.$set(row, 'amount_months', 0);
                this.$set(row, 'salary', 0);
                this.$set(row, 'surcharge', 0);
                delete row.fieldsWithValMessage;
            }
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            resetRow(row);
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    resetRow(item);
                                }
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas(firstLoad = true) {
            this.isLoading = true;

            const loadDataMethods = firstLoad
                                    ? [this.loadDict(), this.loadBaseRowFiles(), this.loadBudgetData()]
                                    : [this.loadBaseRowFiles(), this.loadBudgetData()]
            await Promise.all(loadDataMethods);

            this.budgetForm.splice(0);
            for (const item of this.dict) {
                const existingBudgetData = this.budgetData.find(bd => bd.scholar_type === item.code);
                const dataItem = {
                    id: existingBudgetData ? existingBudgetData.id : this.newRowStartId,
                    id_dict: item.id,
                    code: item.code,
                    parent_code: item.parent_code,
                    avg_annual: existingBudgetData ? existingBudgetData.avg_annual : 0,
                    amount_months: existingBudgetData ? existingBudgetData.amount_months : 0,
                    salary: existingBudgetData ? existingBudgetData.salary : 0,
                    surcharge: existingBudgetData ? existingBudgetData.surcharge : 0,
                    isHasChild: item.is_has_child,
                    files: existingBudgetData ? existingBudgetData.files : 0,
                    row_files: existingBudgetData ? existingBudgetData.row_files : this.baseRowFiles
                };
                Object.defineProperty(dataItem, 'name_local', {
                    get: () => {
                        let lng = 'ru';
                        if (this.$i18n.locale === 'kk') lng = 'kz';
                        let prefix = '';
                        
                        if (item.parent_code) prefix = '-'
                        else {
                            const codeNumb = Number(item.code);
                            if (['1001', '1002'].includes(item.code)) prefix = '1.' + (codeNumb - 1000) + '.';
                            else prefix = codeNumb - 1001 + '.';
                        }
                        return prefix + ' ' + item[`name_${lng}`];
                    }
                });
                
                if (dataItem.isHasChild) {
                    Object.defineProperty(dataItem, 'total', {
                        get: () => {
                            return this.calcSubtotal(dataItem);
                        }
                    });
                } else {
                    Object.defineProperty(dataItem, 'total', {
                        get: () => {
                            const avgAnnual = this.safeDecimal(this.replaceInvalidWithZero(dataItem.avg_annual));
                            const monthSalary = avgAnnual.mul(this.replaceInvalidWithZero(dataItem.salary)).plus(this.replaceInvalidWithZero(dataItem.surcharge));
                            const totalSalary = monthSalary.mul(this.replaceInvalidWithZero(dataItem.amount_months));
                            return totalSalary.div(1000).toDecimalPlaces(2).toNumber();
                        }
                    });
                }
                
                this.budgetForm.push(dataItem);
                this.newRowStartId--;
            }
            this.isLoading = false;
        },

        replaceInvalidWithZero(value) {
            return parseFloat(value) ? value : 0;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/dict_military_scholars/');
                if (response.status === 200) {
                    this.dict = await response.json();
                } else {
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, `Error code: ${response.status}`);
                    this.isLoading = false;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, error.toString());
                this.isLoading = false;
            }
        },

        async loadBaseRowFiles() {
            try {
                const defaultId = -1;
                const response = await fetch(`/api-py/get-new-row-files-form/${defaultId}/${JSON.stringify(this.header)}`);
                if (response.status === 200) {
                    this.baseRowFiles = await response.json();
            } else {
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadBaseRowFiles()`, `Error code: ${response.status}`);
                    this.isLoading = false;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadBaseRowFiles()`, error.toString());
                this.isLoading = false;
            }
        },

        async loadBudgetData() {
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    this.budgetData = await response.json();
                } else {
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadBudgetData()`, `Error code: ${response.status}`);
                    this.isLoading = false;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadBudgetData()`, error.toString());
                this.isLoading = false;
            }
        },

        prepareForSave() {
            const values = [];
            let isHasSavedItem = false;
            let isHasItemWithData = false;

            for (const row of this.budgetForm) {
                if (!this.isValidationPassed(row)) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'scholar_type', row.code);
                this.$set(item, 'avg_annual', parseFloat(row.avg_annual));
                this.$set(item, 'amount_months', parseFloat(row.amount_months));
                this.$set(item, 'salary', parseFloat(row.salary));
                this.$set(item, 'surcharge', parseFloat(row.surcharge));
                this.$set(item, 'row_files', row.row_files);
                
                values.push(item);
                if (item.id > 0) isHasSavedItem = true;
                if (item.avg_annual + item.amount_months + item.salary + item.surcharge > 0) isHasItemWithData = true;
            }
            const isTableVirgin = !isHasSavedItem && !isHasItemWithData;
            if (!isTableVirgin && values.length > 0 && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        isValidationPassed(row) {
            if ('fieldsWithValMessage' in row) {
                this.tableFields.forEach(item => {
                    if (row.fieldsWithValMessage.includes(item.key)) {
                        this.makeToast('warning', this.getErrText('msg'), this.getErrText('cant_be_zero', {fieldName: item.label}));
                    }
                })                
                return false;
            }
            return true;
        },

        calcSubtotal(parent) {
            const sum = this.budgetForm.reduce((acc, row) => {
                    if (row.parent_code === parent.code) return acc + parseFloat(row.total);
                    return acc;
            }, 0);
            const sumDec = this.safeDecimal(sum);
            return sumDec.toDecimalPlaces(2).toNumber();
        }, // пересчет суммы

        async save(values) {
            this.isLoading = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : `/api-py/save-brform${this.form.code}/`
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.deletingAgreementTotalResultHandler(result);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    await this.loadDatas(false);
                } else {
                    this.selectAll = false;
                    await this.loadDatas(false);
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoading = false;
            }
        }, // сохранение данных

        onBlur(item, fieldName, val, digits) {
            this.inputFixed(item, fieldName, val, digits);
            this.dataValidation(item, fieldName, digits);
        },

        dataValidation(item, curField, digits) {
            let valuesSum = 0
            const zeroFields = []
            this.validationFields.forEach(field => {
                let roundedVal = 0;
                if (curField === field) {
                    const val = parseFloat(item[field]) ? item[field] : 0;
                  
                    const decAbsVal = this.safeDecimal(Math.abs(val));
                    roundedVal = decAbsVal.toDecimalPlaces(digits).toNumber();
                } else  {
                    roundedVal = item[field];
                }

                if (roundedVal === 0 && field !== 'surcharge') zeroFields.push(field);
                valuesSum += roundedVal;
            });
            const isMessageNeeded = valuesSum !== 0 && zeroFields.length > 0;
            if (isMessageNeeded) {
                this.$set(item, 'fieldsWithValMessage', zeroFields);
                return
            } else {
                delete item.fieldsWithValMessage;
            }
        }
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.parent_code === null) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_01_324.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'scholar_list',
                        label: this.getFormText('scholar_list')
                    },
                    {
                        key: 'avg_annual',
                        label: `${this.getFormText('avg_annual')} (${this.getUnitsText('people')})`
                    },
                    {
                        key: 'salary',
                        label: `${this.getFormText('salary')} (${this.getUnitsText('tenge')})`
                    },
                    {
                        key: 'surcharge',
                        label: `${this.getFormText('surcharge')} (${this.getUnitsText('tenge')})`
                    },
                    {
                        key: 'amount_months',
                        label: this.getFormText('amount_months')
                    },
                    {
                        key: 'total',
                        label: `${this.getFormText('total_salary')}, ${this.getUnitsText('thousand_tenge')}`
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .child-item {
        padding-left: 15px;
    }
    .form01-149-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
</style>